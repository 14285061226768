import React, { useContext, useEffect } from "react";
import { Accordion, Badge } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useTheme, useThemeDispatch } from '../context/theme-context';
import FormElement from "../components/form/formElement";
import { VisiteContext } from "../services/visiteContext";
import { config } from "../config";
import ThemeLabel from './theme-label';

const ModaleTheme = (props) => {

    const theme = useTheme();
    const dispatchTheme = useThemeDispatch();
    
    useEffect(() => {
        dispatchTheme({type: 'init', theme: props.theme});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const { deleteTheme } = useContext(VisiteContext);

    if(!theme) return null;
    if(!theme.content) return null;

    const { cles, valide, valide2, content, group_visite } = theme;
    const labelLie = (config.group_visit[group_visite].validable && config.group_visit[group_visite].label_lie) && window.navigator.onLine;
    const labelLie2 = (config.group_visit[group_visite].validable && config.group_visit[group_visite].label_lie2) && window.navigator.onLine;

    // const canForceValidate = (files && files.length > 0) && date_fin_label;

    // const setThemeValide = () => {
    //     dispatchTheme({type: 'force-theme-valide', value: 'label'});
    // }

    return(
        <Modal {...props} size="xl" className="modal-theme">
            <Modal.Header closeButton>

                <div className="d-flex align-items-center justify-content-between w-100 me-3 gap-3">
                    <Modal.Title className="lh-sm">
                        <div className="d-flex align-items-center gap-2">
                            {content[0].titre} 
                            {(cles || valide || valide2) && <Badge bg="success" pill><i className="icon icon-check"></i></Badge>}
                        </div>
                    </Modal.Title>
                    <div className="d-flex">
                        {/* {config.group_visit[group_visite].validable && !config.group_visit[group_visite].label_lie2 && <div>
                            <button className="btn btn-outline-success" onClick={setThemeValide} disabled={!canForceValidate}>{(valide) ? "Invalider" : "Valider"}</button>
                        </div>} */}

                        <button className="btn" onClick={deleteTheme}><i className="icon icon-trash fs-4 text-danger"></i></button>
                    </div>
                </div>

            </Modal.Header>

            <Modal.Body>

                {labelLie && <ThemeLabel theme={theme} label='label' onUpdate={props.onUpdate}/>}
                {labelLie2 && <ThemeLabel theme={theme} label='label2' onUpdate={props.onUpdate}/>}

                {content[0].titre_footer &&
                    <div className="h4">
                        {content[0].titre_footer}
                    </div>
                }
                    <Accordion defaultActiveKey={content[0].id}>
                        <form>
                            {content.map((block, i) => <FormElement key={i} block={block} aide={'Que dalle !'}/>)}
                        </form>
                    </Accordion>
            </Modal.Body>
        </Modal>
)
}

export default ModaleTheme;
