import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { config } from "../../config";
import ThemeItem from "./theme-item";

const isWindow = navigator.appVersion.indexOf("Win") !== -1;

const Thematiques = ({ action, toggler, visite }) => {

    const themes = [];
    for(const theme in config.group_visit){
        if(theme.startsWith("th_")){
            themes.push({id: theme, ...config.group_visit[theme]});
        }
    }

    return(
        <div className="sidebar sidebar-themes">
            <div className="sidebar-header bg-light sticky-top border-bottom">
                <h3 className="fs-5 mb-0">Thématiques</h3>
                <button className="btn sidebar-toggler" onClick={() => toggler('themes')}>
                    <i className="icon icon-arrow-left"></i>
                    <i className="icon icon-arrow-right"></i>
                </button>
            </div>
            <div className={`sidebar-content${isWindow ? '' : ' hide-scrollbar'}`}>
                <div className="thematiques-container">
                    <ListGroup variant="flush">
                        {themes.map(theme =>
                            <ListGroupItem action onClick={() => action(theme)} className="d-flex justify-content-between" key={theme.id} value={theme.id}>
                                <ThemeItem theme={theme} visite={visite} />
                            </ListGroupItem>)
                        }
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}

export default Thematiques;
