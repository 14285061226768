import { config } from "../config";
import { dbAdd, dbGet } from "./indexedDBService";
import { getCurrentUser } from "./usersService";
import { getCurrentVisite } from "./visitesService";

let currentTheme;

const loadThemeContent = async (theme, visiteUid) => {

    currentTheme = null;

    // Vérifier si ce contenu n'existe pas pour cette visite
    const data = await dbGet('visits_content', [visiteUid, theme.id]);

    if(data){
        currentTheme = data;
    } else {
        const result = await import("../form-schema/" + theme.id + "-schema." + theme.version + ".json");
        const nextData = JSON.parse(JSON.stringify(result.default));
        currentTheme = { uid: visiteUid, content: nextData, group_visite: theme.id, valide: false, validable: theme.validable, label_lie: theme.label_lie, label_lie2: theme.label_lie2 };
    }

    return currentTheme;
}

const getElementsCount = (children) => {

    let count = 0;
    const types = ['checkbox', 'radio', 'checkboxgroup'];

    function countInputs(elements) {
        elements.forEach(element => {
            if(types.includes(element.type) && !element.facultatif){
                count += 1;
            } else if(element.type === "checkboxgroup"){
                count += 1;
            } else if(element.elements){
                countInputs(element.elements);
            }
        });
    }

    countInputs(children);

    return count;
}

const getInputsChecked = (themeId, visite=null) => {

    let totalChecked = 0;
    const currentVisite = visite?visite:getCurrentVisite();
    const currentContent = currentVisite.contents.find(content => content.group_visite.startsWith(themeId));

    const countFromChildren = (children) => {
        children.forEach(element => {
            if(element.valeur || element.valide) {
                totalChecked += 1;
            } else if(element.elements){
                countFromChildren(element.elements);
            }
        });
    }

    if(currentContent){
        const children = currentContent.content[0].elements.filter(item => !item.facultatif);
        countFromChildren(children);
    }

    return totalChecked;
}

const getScoreFromTheme = (theme) => {

    let totalChecked = 0;

    const countFromChildren = (children) => {
        children.forEach(element => {
            if ((element.valeur || element.valide) && element.facultatif !== true) {
                totalChecked += 1;
            } else if (element.elements) {
                countFromChildren(element.elements);
            }
        });
    }

    if(theme){
        const children = theme.content[0].elements;
        countFromChildren(children);
    }

    return totalChecked;
}

const getThemeCles = (theme) => {

    const total = getInputsChecked(theme.group_visite);
    const totalToCheck = getElementsCount(theme.content[0].elements);

    //si aucun critère dans le schéma, c'est un label
    if(totalToCheck === 0){
        return false;
    } else {
        return theme.cles = total >= totalToCheck;
    }

}

const toggleThemeValide = async (theme) => {

    let isValide = getThemeCles(theme);

    if(!isValide){
        isValide = !theme.valide;
    }

    theme.valide = isValide;

    return await dbAdd('visits_content', {...theme})
    .then(id => {
        return isValide;
    });
}

const forceThemeValide = async(theme) => {

    if(window.navigator.onLine){
        const hasFiles = theme.files.filter(file => file.startsWith(theme.group_visite));
        if(theme.date_fin_label && (hasFiles.length > 0)){
            theme.valide = !theme.valide;
        } else {
            theme.valide = false;
        }
    }
    
    return await dbAdd('visits_content', {...theme})
    .then(() => {
        return theme.valide;
    });
}

const forceThemeValide2 = async(theme) => {

    if(window.navigator.onLine){
        const hasFiles = theme.files.filter(file => file.startsWith('label2_'));
        if(theme.date_fin_label2 && (hasFiles.length > 0)){
            theme.valide2 = !theme.valide2;
        } else {
            theme.valide2 = false;
        }
    }
    
    return await dbAdd('visits_content', {...theme})
    .then(() => {
        return theme.valide2;
    });
}

const getIsTheme = (theme) => {
    const visite = getCurrentVisite();
    const currentContent = visite.contents.find(content => content.group_visite.startsWith(theme.id));
    if(currentContent){
        return currentContent.valide;
    } else {
        return false;
    }
}

const getThemeIsPristine = (theme) => {

    const getcheckedElements = (elements) => {

        let total = 0

        elements.forEach(element => {
            switch(element.type){
                case 'checkbox':
                    if(element.valeur) total += 1;
                    break;
                default:
                    break;
            }
        });

        return total;
    }

    return getcheckedElements(theme.content[0].elements) === 0;
}

const deleteThemeFromServer = async (theme) => {
    try {
        const { email, mdp } = getCurrentUser();
        const pass = encodeURIComponent(mdp);
        const host = config.apiData.domain;
        const api = config.apiData.entities.visits.theme_delete;
        let urlApiDelete = `${host}${api}&login=${email}&pass=${pass}&visit_uid=${theme.uid}&group_visite=${theme.group_visite}`;
        //Si ce theme a un label lié, le passer en param
            if(theme.label_lie){
                urlApiDelete += "&label_lie="+theme.label_lie;
            }
            if(theme.label_lie2 && theme.valide2){
                urlApiDelete += "&label_lie2="+theme.label_lie2;
            }
        const response = await fetch(urlApiDelete);

        if(response.ok){
            return true;
        } else {
            console.warn("ERREUR LORS DE LA SUPPRESSION DU THÈME !");
            return false;
        }
    } catch (error) {
        console.warn('ERREUR : ', error);
        return false;
    }
}

export {
    loadThemeContent,
    getElementsCount,
    getInputsChecked,
    getThemeCles,
    toggleThemeValide,
    getIsTheme,
    forceThemeValide,
    forceThemeValide2,
    getScoreFromTheme,
    getThemeIsPristine,
    deleteThemeFromServer,
}
