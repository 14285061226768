import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from 'react-router-dom';

import { getIsForVisite } from "../services/visitesService";
import { VisiteContext } from "../services/visiteContext";
import { loadUserPrefs, setUserPref, subscribeUserPrefs } from "../services/userPrefsService";
import useVisite from '../services/useVisite';

import FormElement from "../components/form/formElement";
import Thematiques from "../components/visite-edit/thematiques";
import VisiteSommaire from "../components/visite-edit/visite-sommaire";
import VisitEditHeader from "../components/visite-edit/visit-edit-header";
import ModaleTheme from "../components/modal-theme";
import { ThemeProvider } from "../context/theme-context";

const isWindow = navigator.appVersion.indexOf("Win") !== -1;

const VisitEdit = () => {
    
    const visiteId = useParams().id;

    const contentRef = useRef();
    const accordionRef = useRef();

    const [frameState, setFrameState] = useState({showThemes: true, showSommaire: true});
    const [currentIndex, setCurrentIndex] = useState('0');

    const {
        visite,
        locationRef,
        elements,
        clesCalculees,
        copy,
        selectedTheme,
        handleChange,
        duplicateElement,
        removeElement,
        deleteTheme,
        loadTheme,
        closeThemeModal
    } = useVisite(visiteId);

    /* Scroll vers nouveau block lors de la copie d'un block */
    useEffect(() => {
        if(copy){
            const blockTitle = document.getElementById(copy);
            if(blockTitle) {
                blockTitle.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
            // const block = blockTitle.closest('.accordion-item');
            // if(contentRef && contentRef.current && block){
            //     contentRef.current.scrollTop = block.offsetTop - 100;
            // }
        }
    }, [copy]);

    /* Préférences de l'utilisateur sur les panneaux latéraux */
    useEffect(() => {

        // Charger les préférences de l'utilisateur
        loadUserPrefs();

        // Abonnement au service des préférences
        const onPrefs = (prefs) => {
            setFrameState({
                showThemes: prefs.showVisiteThemes,
                showSommaire: prefs.showVisiteSommaire
            });
        }

        const unsubscribe = subscribeUserPrefs(onPrefs);

        return () => {
            unsubscribe(onPrefs);
        };

    }, []);

    const toggleSidebar = (target) => {
        if(target === "themes"){
            setUserPref({showVisiteThemes: !frameState.showThemes});
        }
        if(target === "sommaire"){
            setUserPref({showVisiteSommaire: !frameState.showSommaire});
        }
    }

    const onItemClick = (id) => {
        setCurrentIndex(id);
        if(accordionRef.current){

            let nextId = ".item-" + id;

            if(id.indexOf('~') !== -1){
                nextId = ".item-" + id.substring(0, id.indexOf('~')) + "\\~" + id.substring(id.indexOf('~') + 1, id.length);
            }

            const item = accordionRef.current.querySelector(nextId);
            const button = item.querySelector('.accordion-button');
            const visiteContent = contentRef.current;

            if(!visiteContent) return;

            const topPosition = 0;

            setTimeout(() => {
                if(item && (item.offsetTop > topPosition)){
                    visiteContent.scrollTop = (item.offsetTop - topPosition);
                } else {
                    visiteContent.scrollTop = 0;
                }
            }, 500);

            button.click();
        }
    };

    if(!elements || !elements.length) return false;

    if(!Array.isArray(elements)){
        throw new Error(`Données de la visite ${visiteId} mal formatées ou incomplètes.`);
    }

    const showVisiteSommaire = getIsForVisite(visite);

    if(!locationRef.current) {
        return (
            <div className="p-5">
                <div className="card w-50 mx-auto">
                    <div className="card-body">
                        <h2 className="text-danger">Une erreur s'est produite</h2>
                        <p className="mb-0">Le bien correspondant à cette visite n'est pas dans la base de données.</p>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <VisiteContext.Provider value={{handleChange, elements, locationRef, duplicateElement, removeElement, deleteTheme}}>
            <VisitEditHeader
                hlo={locationRef.current.loc_hlo}
                agr={locationRef.current.agr}
                title={locationRef.current.name}
                clesCalcul={clesCalculees}
                cles={visite.cles}
                date={visite.date_visite}
                visite={visite}
            />
            <div className={`content-body ${isWindow ? '' : 'hide-scrollbar'} content-visite${frameState.showThemes ? " themes-open" : ""}${frameState.showSommaire ? " sommaire-open" : ""}`}>

                {showVisiteSommaire ? <VisiteSommaire data={elements} visite={visite} toggler={toggleSidebar} itemHandler={onItemClick} current={currentIndex} />
                    : <div></div>
                }

                <div className="visit-content px-5" ref={contentRef}>
                    <form className="my-4" onSubmit={(e) => e.preventDefault()}>
                        <Accordion ref={accordionRef} defaultActiveKey={"0"}>
                            {elements.map((block, i) => <FormElement key={i} block={block} itemHandler={onItemClick} />)}
                        </Accordion>
                    </form>
                </div>

                <Thematiques action={loadTheme} toggler={toggleSidebar} visite={visite} />

                {selectedTheme && <ThemeProvider><ModaleTheme show={true} onHide={closeThemeModal} theme={selectedTheme} /></ThemeProvider>}

            </div>
        </VisiteContext.Provider>
    );
}

export default VisitEdit;
