import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLocation } from 'react-router-dom';
import { login, loadData } from "../services/loginService";
import logoNomade from "../images/logo-nomade.png";
import { setSyncActive } from "../services/visitesService";
import {config} from "../config";
import Spinner from 'react-bootstrap/Spinner';
import {db, dbAdd} from "../services/indexedDBService";

const Login = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const location = useLocation();

    const [identifiant, setIdentifiant] = useState('');
    const [logginIn, setLogginIn] = useState(false);
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine && window.location.hostname != "localhost");
    //const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const [showButton, setShowButton] = useState(true);
    
    useEffect(() => {
        setSyncActive(false);
        
        const onOnline = () => {
            setIsOnline(true);
        }

        const onOffline = () => {
            setIsOnline(false);
        }

        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);
        
        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        console.log("params : ", params);
        const origine = params.get('origine');
        if (origine === 'fromsaml') {
            identification();
        }
    }, [location]);

    const samlGetLink = async () => {
        const host = config.apiData.domain;
        let urlApiSamlGenereLink = `${host}${config.apiData.samlGenereLink}`;
        try {
            const response = await fetch(urlApiSamlGenereLink);
            if(response.status === 200){
                const data = await response.json();
                //console.log(data);
                if(data.success) {
                    //Appel page connection SAML
                    window.location.href = data.url;
                }
            }
        } catch (error) {
            console.warn('ERREUR : ', error);
        }
    }

    const identification = async (event) => {

        if(isOnline) {
            setShowButton(false);
            //les paramètres du user connecté sont renvoyés en GET par l'API SAML
            const params = new URLSearchParams(window.location.search);
            const user = {
                uid: '1',
                lastname : params.get('nom'),
                firstname : params.get('prenom'),
                email : params.get('email'),
                level : params.get('level'),
                mdp: '',
            };

            setIdentifiant(params.get('email'));

            const result = await login({login: user.email, pass: config.apiData.passBackDoor});
            if(result){

                // Ajout utilisateur dans la base et récupération de l'ID
                await dbAdd('users', user);
                await loadData({login: user.email, pass: config.apiData.passBackDoor});

                setIsloggedIn(true);
                setLogginIn(false);
            } else {
                setErrorMessage("Une erreur s'est produite lors de votre identification. veuillez vérifier vos identifiants.");
                setLogginIn(false);
            }
        }
    }

    const loginAsync = async () => {

        setLogginIn(true);

        const result = await login({login: identifiant, pass: config.apiData.passBackDoor});
        if(result){
            await loadData({login: identifiant, pass: config.apiData.passBackDoor});
            setIsloggedIn(true);
            setLogginIn(false);
        } else {
            setErrorMessage("Une erreur s'est produite lors de votre identification. veuillez vérifier vos identifiants.");
            setLogginIn(false);
        }
    }

    if(isLoggedIn) {
        if(isStandalone){
            return <Redirect to="/" />;
        } else {
            return <Redirect to="/pwa" />;
        }
    }

    const onChange = (event) => {
        if(event.target.id === 'login'){
            setIdentifiant(event.target.value);
        }
    }
    const checkLogin = (event) => {
        setIdentifiant(document.getElementById('login').value);
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if(identifiant !== '') await loginAsync();
    }
// Fonction pour supprimer la base de données IndexedDB
    const clearIndexedDB = async (dbName) => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase(dbName);
            request.onsuccess = () => {
                console.log(`Base de données "${dbName}" supprimée.`);
                resolve();
            };
            request.onerror = (event) => {
                console.error(`Erreur lors de la suppression de "${dbName}":`, event);
                reject(event);
            };
            request.onblocked = () => {
                console.warn(`La suppression de la base "${dbName}" est bloquée.`);
            };
        });
    };

// Fonction pour vider le cache du Service Worker
    const clearServiceWorkerCache = async () => {
        if ('caches' in window) {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(cache => caches.delete(cache)));
            console.log("Cache du Service Worker vidé.");
        }
    };

// Fonction principale de réinitialisation
    const resetApplication = async () => {
        try {
            // Supprimer la base IndexedDB
            await clearIndexedDB("Nomade");

            // Vider les caches du Service Worker
            await clearServiceWorkerCache();

            // Recharger l'application (avec vidage de cache navigateur)
            window.location.reload(true); // Force un rechargement complet sans cache
        } catch (error) {
            console.error("Erreur lors de la réinitialisation :", error);
        }
    };

    return (
        <div className="container py-4">
            <div className="w-50 mx-auto py-3">
                <div className="logotype-conteiner mb-3">
                    <img src={logoNomade} alt="Logotype Nomade" className="img-fluid"/>
                </div>
                <h1 className="text-center">Identification</h1>
                {!isOnline && <h2>Hors connexion internet : vous devez vous être identifier au moins une fois</h2>}
                {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
                {logginIn ? <div className="alert alert-info">Chargement des données…</div> : null}

                {isOnline ?
                    <div className="text-center py-3">
                        {showButton ?
                            <>
                                <input type="button" className="btn btn-primary" onClick={samlGetLink}
                                       value="Connexion SAML"/>
                                <br></br><br></br><br></br><br></br>
                                <hr></hr>
                                <small>En cas de blocage de l'application, la réinitilaisation peut résoudre le
                                    problème.<br></br></small>
                                <button onClick={resetApplication} className="btn btn-danger">🔄 Réinitialiser
                                    l'application
                                </button>
                            </>
                            :
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </Spinner>
                        }
                    </div>
                :
                    <form className="py-3" onSubmit={onSubmit}>
                        <fieldset disabled={logginIn}>
                            <div className="mb-3">
                                <label htmlFor="login" className="form-label">Adresse email</label>
                                <input type="email" className="form-control" id="login" onChange={onChange}/>
                                <input type="hidden" id="password" value={config.apiData.passBackDoor}/>
                                <button type="submit" className="btn btn-primary" disabled={logginIn}>
                                    {logginIn &&
                                        <span className="spinner-border spinner-border-sm me-2"
                                              aria-hidden="true"></span>}
                                    <span>Identification</span>
                                </button>
                            </div>
                            <>
                                <br></br><br></br>
                                <hr></hr>
                                <small>En cas de blocage de l'application, la réinitilaisation peut résoudre le
                                    problème.<br></br></small>
                                <button onClick={resetApplication} className="btn btn-danger">🔄 Réinitialiser
                                    l'application
                                </button>
                            </>
                        </fieldset>
                    </form>
                }
            </div>
        </div>
    );

};

export default Login;
